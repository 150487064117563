import React from "react";
import ReactSelect from "react-select";
import { ErrorMessage, Field, useField } from "formik";

export const Select = ({
  className,
  label,
  name,
  options,
  onChange = () => {},
  placeholder,
  required,
  isSearchable = false,
}) => {
  const [field, meta, helpers] = useField({ name });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border:
        meta.touched && meta.error ? "1px solid #E53935" : "1px solid #D1D5DB",
      borderRadius: "8px",
      padding: "2px",
      backgroundColor: "#F9FAFB",
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        border: "1px solid #D1D5DB",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#fff" : "#fff",
      color: state.isSelected ? "#111928" : "#111928",
      padding: "6px",
      marginBottom: "8px",
      "&:hover": {
        borderRadius: "8px",
        backgroundColor: "#F3F4F6",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111928",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "0",
      padding: "12px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };

  const defaultOption = { value: "", label: `Select ${label}` };
  const updatedOptions = [defaultOption, ...options];

  return (
    <div className={`${className} flex flex-col gap-2 p-2`}>
      {label && (
        <label htmlFor={name} className="text-gray-900 text-sm font-medium">
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <ReactSelect
        id={name}
        name={name}
        options={updatedOptions}
        onChange={(selectedOption) => {
          helpers.setValue(selectedOption.value);
          onChange({ target: { name, value: selectedOption.value } });
        }}
        placeholder={`Select ${label}`}
        styles={customStyles}
        isSearchable={isSearchable}
      />
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm mt-1"
      />
    </div>
  );
};
